import {MediaFrame} from './MediaFrame';
import {createMediaFrame} from './createMediaFrame';

const mediaFrame: MediaFrame = createMediaFrame();

if (typeof window !== 'undefined' && !window.MediaFrame) {
  window.MediaFrame = mediaFrame;
}

export {mediaFrame};
